import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_ForgotPassword = _resolveComponent("ForgotPassword")

  return (_openBlock(), _createBlock(_component_ForgotPassword, {
    "state-token": _ctx.stateToken,
    email: _ctx.email,
    mobileapp: _ctx.mobileapp
  }, null, 8, ["state-token", "email", "mobileapp"]))
}